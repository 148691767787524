<template>
<div class="mainContent">
  <div class="phoneTemplate">
    <div class="main-box">
      <div class="divcontent">
        <h3 class="main-title">老年人能力评估基本信息表</h3>
        <!--AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA-->
        <h4>A.1 评估基本信息表</h4>
        <h5>A.1.1 评估编号</h5>
        <el-input type="text" placeholder="请输入评估编号"></el-input>
        <h5 class="mt10">A.1.2 评估基准日期</h5>
        <el-input type="text" placeholder="请选择日期"></el-input>
        <!--    <el-date-picker
              type="date"
              style="width: 100%"
              placeholder="请选择日期">
            </el-date-picker>-->
        <h5 class="mt10">A.1.3 评估原因</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">接受服务前初评</el-radio>
            <el-radio class="mb10" :label="6">接受服务后的常规评估</el-radio>
            <el-radio class="mb10" :label="9">接受服务后的常规评估</el-radio>
            <el-radio class="mb10" :label="9">因评估结果有疑问进行的复评</el-radio>
          </div>
        </el-radio-group>
        <h4>A.2 被评估者的基本信息</h4>
        <h5 class="mt10">A.2.1 姓名</h5>
        <el-input type="text" placeholder="请输入姓名"></el-input>
        <h5 class="mt10">A.2.2 性别</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">1 男</el-radio>
            <el-radio class="mb10" :label="6">2 女</el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.2.3 出生日期</h5>
        <el-input type="text" placeholder="请请选择出生日期"></el-input>
        <h5 class="mt10">A.2.4 身份证号</h5>
        <el-input type="text" placeholder="请输入身份证号"></el-input>
        <h5 class="mt10">A.2.5 社保卡号</h5>
        <el-input type="text" placeholder="请输入社保卡号"></el-input>
        <h5 class="mt10">A.2.6 民族</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">1 汉族</el-radio>
            <el-radio class="mb10" :label="6">2 少数民族 <span><el-input type="text" style="width: 50%" placeholder="请输入民族"></el-input></span></el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.2.7 文化程度</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">1 文盲</el-radio>
            <el-radio class="mb10" :label="6">2 小学</el-radio>
            <el-radio class="mb10" :label="6">3 初中</el-radio>
            <el-radio class="mb10" :label="6">4 高中/技校/中专</el-radio>
            <el-radio class="mb10" :label="6">5 大学专科及以上</el-radio>
            <el-radio class="mb10" :label="6">6 不详</el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.2.7 宗教信仰</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">0 无</el-radio>
            <el-radio class="mb10" :label="6">1 有 <span><el-input type="text" style="width: 50%" placeholder="请输入宗教信仰"></el-input></span></el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.2.9 婚姻状况</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">1 未婚</el-radio>
            <el-radio class="mb10" :label="6">2 已婚</el-radio>
            <el-radio class="mb10" :label="6">3 丧偶</el-radio>
            <el-radio class="mb10" :label="6">4 离婚</el-radio>
            <el-radio class="mb10" :label="6">5 未说明的婚姻状况</el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.2.10 居住情况</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">1 独居</el-radio>
            <el-radio class="mb10" :label="6">2 与配偶/伴侣居住</el-radio>
            <el-radio class="mb10" :label="6">3 与子女居住</el-radio>
            <el-radio class="mb10" :label="6">4 与父母居住</el-radio>
            <el-radio class="mb10" :label="6">5 与兄弟姐妹居住</el-radio>
            <el-radio class="mb10" :label="6">6 与其他亲属居住</el-radio>
            <el-radio class="mb10" :label="6">7 与非亲属关系的人居住</el-radio>
            <el-radio class="mb10" :label="6">8 养老机构</el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.2.11 医疗费用支付方式(多选，可选4个)</h5>
        <el-checkbox-group :value="value1">
          <div style="display:flex;flex-direction: column">
            <el-checkbox class="mb10" label="">1 城镇职工基本医疗保险</el-checkbox>
            <el-checkbox class="mb10" label="">2 城镇居民基本医疗保险</el-checkbox>
            <el-checkbox class="mb10" label="">3 新型农村合作医疗</el-checkbox>
            <el-checkbox class="mb10" label="">4 贫困救助</el-checkbox>
            <el-checkbox class="mb10" label="">5 商业医疗保险</el-checkbox>
            <el-checkbox class="mb10" label="">6 全公费</el-checkbox>
            <el-checkbox class="mb10" label="">7 全自费</el-checkbox>
            <el-checkbox class="mb10" label="">
              8 其他
              <span><el-input type="text" style="width: 50%" placeholder="请输入"></el-input></span>
            </el-checkbox>
          </div>
        </el-checkbox-group>
        <h5 class="mt10">A.2.12 经济来源(多选，可选4个)</h5>
        <el-checkbox-group :value="value1">
          <div style="display:flex;flex-direction: column">
            <el-checkbox class="mb10" label="">1 退休金/养老金</el-checkbox>
            <el-checkbox class="mb10" label="">2 子女补贴</el-checkbox>
            <el-checkbox class="mb10" label="">3 亲友资助</el-checkbox>
            <el-checkbox class="mb10" label="">
              4 其他补贴
              <span><el-input type="text" style="width: 50%" placeholder="请输入"></el-input></span>
            </el-checkbox>
          </div>
        </el-checkbox-group>
        <h4 class="mt10">A.2.13 疾病诊断</h4>
        <h5 class="mt10">A.2.13.1 痴呆</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">0 无</el-radio>
            <el-radio class="mb10" :label="6">1 轻度</el-radio>
            <el-radio class="mb10" :label="6">2 中度</el-radio>
            <el-radio class="mb10" :label="6">3 重度</el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.2.13.2 精神疾病</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">0 无</el-radio>
            <el-radio class="mb10" :label="6">1 精神分裂症</el-radio>
            <el-radio class="mb10" :label="6">2 双相情感障碍</el-radio>
            <el-radio class="mb10" :label="6">3 偏执性精神障碍</el-radio>
            <el-radio class="mb10" :label="6">4 分裂情感性障碍</el-radio>
            <el-radio class="mb10" :label="6">5 癫痫所致精神障碍</el-radio>
            <el-radio class="mb10" :label="6">6 精神发育迟滞伴发精神障碍</el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.2.13.3 慢性疾病</h5>
        <el-input type="text" placeholder="请输入"></el-input>
        <h4 class="mt10">A.2.14 近30天内意外事件</h4>
        <h5 class="mt10">A.2.14.1 跌倒</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">0 无</el-radio>
            <el-radio class="mb10" :label="6">1 发生过 1 次</el-radio>
            <el-radio class="mb10" :label="6">2 发生过 2 次</el-radio>
            <el-radio class="mb10" :label="6">3 发生过 3 次及以上</el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.2.14.2 走失</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">0 无</el-radio>
            <el-radio class="mb10" :label="6">1 发生过 1 次</el-radio>
            <el-radio class="mb10" :label="6">2 发生过 2 次</el-radio>
            <el-radio class="mb10" :label="6">3 发生过 3 次及以上</el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.2.14.4 噎食</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">0 无</el-radio>
            <el-radio class="mb10" :label="6">1 发生过 1 次</el-radio>
            <el-radio class="mb10" :label="6">2 发生过 2 次</el-radio>
            <el-radio class="mb10" :label="6">3 发生过 3 次及以上</el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.2.14.4 自杀</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10"  :label="3">0 无</el-radio>
            <el-radio class="mb10" :label="6">1 发生过 1 次</el-radio>
            <el-radio class="mb10" :label="6">2 发生过 2 次</el-radio>
            <el-radio class="mb10" :label="6">3 发生过 3 次及以上</el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.2.14.5 其他</h5>
        <el-input type="text" placeholder="请输入"></el-input>
        <h4 class="mt10">A.3 信息提供者及联系人信息表</h4>
        <h5 class="mt10">A.3.1 信息提供者的姓名</h5>
        <el-input type="text" placeholder="请输入"></el-input>
        <h5 class="mt10">A.3.2 信息提供者与老人关系</h5>
        <el-radio-group>
          <div style="display:flex;flex-direction: column">
            <el-radio class="mb10" :label="6">1 配偶</el-radio>
            <el-radio class="mb10" :label="6">2 子女</el-radio>
            <el-radio class="mb10" :label="6">3 其他亲属</el-radio>
            <el-radio class="mb10" :label="6">4 雇佣照顾者</el-radio>
            <el-radio class="mb10" :label="6">5 其他</el-radio>
          </div>
        </el-radio-group>
        <h5 class="mt10">A.3.4 联系人姓名</h5>
        <el-input type="text" placeholder="请输入联系人姓名"></el-input>
        <h5 class="mt10">A.3.5 联系人电话</h5>
        <el-input type="text" placeholder="请输入联系人电话"></el-input>
        <!--BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB-->
        <div class="mt40">
          <h3 class="main-title ">老年人能力评估基本信息表</h3>
          <h4>B.1 日常生活活动评估表</h4>
          <h5>B.1.1 进食</h5>
          <div class="mb10">指用餐具将食物由容器送到口中、咀嚼、吞咽等过程</div>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">10分，可独立进食（在合理的时间内独立进食<br/>准备好的食物）</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                  5分， 需部分帮助（进食过程中需要一定帮<br/>助，如协助把持餐具）
                </span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                  0分， 需极大帮助或完全依赖他人，或有留<br/>置营养管
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.1.2 洗澡</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">5分， 准备好洗澡水后，可自己独立完成洗<br/>澡过程</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 0分， 在洗澡过程中需他人帮助
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.1.3 修饰</h5>
          <div class="mb10">指洗脸、刷牙、梳头、刮脸等</div>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">5分， 可自己独立完成</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 0分， 需他人帮助
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.1.4 穿衣</h5>
          <div class="mb10">指穿脱衣服、系扣、拉拉链、穿脱鞋袜、系鞋带</div>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">10分，可独立完成</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 5分， 需部分帮助（能自己穿脱，但需他人<br/>帮助整理衣物、系扣/鞋带、拉拉链）
                </span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 0分， 需极大帮助或完全依赖他人
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.1.5 大便控制</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">10分，可控制大便</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 5分， 偶尔失控（每周<1 次），或需要他<br/>人提示
                </span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 0分， 完全失控
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.1.6 小便控制</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">10分，可控制小便</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 5分， 偶尔失控（每天<1 次，但每周 > 1 <br/>次），或需要他人提示
                </span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 0分， 完全失控，或留置导尿管
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.1.7 如厕</h5>
          <div class="mb10">包括去厕所、解开衣裤、擦净、整理衣裤、冲水</div>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">10分，可独立完成</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 5分， 需部分帮助(需他人搀扶去厕所、需<br/>他人帮忙冲水或整理衣裤等)
                </span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 0分， 需极大帮助或完全依赖他人
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.1.8 床椅转移</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">15分，可独立完成</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                10分，需部分帮助(需他人搀扶或使用拐杖)
                </span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 5分， 需极大帮助(较大程度上依赖他人搀<br/>扶和帮助)
                </span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 0分， 完全依赖他人
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.1.9 平地行走</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">15分，可独立在平地上行走 45m</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">10分，需部分帮助（因肢体残疾、平衡能力<br/>差、过度衰弱、视力等问题，在一定程度上需他<br/>人地搀扶或使用拐杖、助行器等辅助用具）</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 5分， 需极大帮助（因肢体残疾、平衡能力差、过度衰弱、视力<br/>等问题，在较大程度上依赖他人搀扶，或坐在轮椅<br/>上自行移动）
                </span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                0分， 完全依赖他人
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.1.10 上下楼梯</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">10分，可独立上下楼梯（连续上下 10-15个<br/>台阶）</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                5分， 需部分帮助（需他人搀扶，或扶着楼<br/>梯、使用拐杖等）
                </span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                 0分，需极大帮助或完全依赖他人
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.1.11 日常生活活动总分</h5>
          <div class="mb10">上述10个项目得分之和</div>
          <el-input type="text" placeholder="请输入"></el-input>
          <h5 class="mt10">B.1 日常生活活动分级</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio class="mb10" :label="6">1 能力完好：总分 100 分</el-radio>
              <el-radio class="mb10" :label="6">2 轻度受损：总分 65-95 分</el-radio>
              <el-radio class="mb10" :label="6">3 中度受损：总分 45-60 分</el-radio>
              <el-radio class="mb10" :label="6">4 重度受损：总分≤40 分</el-radio>
            </div>
          </el-radio-group>
          <h5>B.2 精神状态评估表</h5>
          <h5 class="mt10">B.2.1 认知功能</h5>
          <div class="mb10">(1) 画钟测验：“请您在这儿画一个圆形的时钟，在时钟上标出 10 点 45 分”</div>
          <autograph/>
          <div class="mb10">(2) 回忆词语：“我说三样东西，请重复一遍，并记住，一会儿会问您”：苹果、手表、国旗<br/>现在请您告诉我，刚才我要您记住的三样东西是什么？</div>
          <el-input type="text" placeholder="请输入"></el-input>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">0分，画钟正确（画出一个闭锁圆，指针位<br/>置准确），且能回忆出 2-3 个词</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">1分，画钟错误（画的圆不闭锁，或指针位<br/>置不准确），或只回忆出 0-1 个词</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                2 分，已确诊为认知障碍，如老年痴呆
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.2.2 攻击行为</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">0分，无身体攻击行为（如打/踢/推/咬/抓/摔<br/>东西）和语言攻击行为(如骂人、语言威胁、尖<br/>叫)</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">1分，每月有几次身体攻击行为，或每周有<br/>几次语言攻击行为</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                2分，每周有几次身体攻击行为，或每日有<br/>语言攻击行为
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.2.3 抑郁症状</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio class="mb10" :label="6">0分，无</el-radio>
              <el-radio class="mb10" :label="6"><span class="line24">1分，情绪低落、不爱说话、不爱梳洗、不<br/>爱活动</span></el-radio>
              <el-radio class="mb10" :label="6">2分，有自杀念头或自杀行为</el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.2.3 上述状态总分</h5>
          <div class="mb10">上述3个项目得分之和</div>
          <el-input type="text" placeholder="请输入"></el-input>
          <h5 class="mt10">B.2 精神状态分级</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio class="mb10" :label="3">1 能力完好：总分为 0 分</el-radio>
              <el-radio class="mb10" :label="6">2 轻度受损：总分为 1 分</el-radio>
              <el-radio class="mb10" :label="6">3 中度受损：总分 2-3 分</el-radio>
              <el-radio class="mb10" :label="6">4 重度受损：总分 4-6 分</el-radio>
            </div>
          </el-radio-group>
          <h4 class="mt10">B.3 感知觉与沟通评估表</h4>
          <h5 class="mt10">B.3.1 意识水平</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">0分，神志清醒，对周围环境警觉</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">1分，嗜睡，表现为睡眠状态过度延长。当<br/>呼唤或推动其肢体时可唤醒，并能进行正确的<br/>交谈或执行指令，停止刺激后又继续入睡</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                2分，昏睡，一般的外界刺激不能使其觉醒，<br/>给予较强烈的刺激时可有短时的意识清醒，醒后<br/>可简短回答提问，当刺激减弱后又很快进入睡眠<br/>状态
                </span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">
                3分，昏迷，处于浅昏迷时对疼痛刺激有回<br/>避和痛苦表情；处于深昏迷时对刺激无反应(若<br/>评定为昏迷，直接评定为重度失能，可不进行以<br/>下项目的评估)
                </span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.3.2 视力</h5>
          <div class="mb10">若平时带老花镜或近视镜，应在佩戴眼镜的情况下评估</div>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">0分，能看清书报上的标准字体</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">1分，能看清楚大字体，但看不清书报上的<br/>标准字体</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">2分，视力有限，看不清报纸大标题，但能<br/>辨认物体</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">3分，辨认物体有困难，但眼睛能跟随物体<br/>移动，只能看到光、颜色和形状</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">4分，没有视力，眼睛不能跟随物体移动</span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.3.3 沟通交流</h5>
          <div class="mb10">包括非语言沟通</div>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">0分，无困难，能与他人正常沟通和交流</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">1分，能够表达自己的需要及理解别人的话<br/>但需要增加时间或给予帮助</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">2分，表达需要或理解有困难，需频繁重复<br/>或简化口头表达</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">3分，不能表达需要或理解他人的话</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">4分，没有视力，眼睛不能跟随物体移动</span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.3 感知觉与沟通分级</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">0 能力完好：意识清醒，且视力和听力评为<br/> 0 或 1，沟通评为 0</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">1 轻度受损：意识清醒，但视力或听力中至<br/>少一项评为 2，或沟通评为 1</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">2 中度受损：意识清醒，但视力或听力中至<br/>少一项评为 3，或沟通评为 2； 或嗜睡，视力或<br/>听力评定为 3 及以下，沟通评定为 2 及以下</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">3 重度受损：意识清醒或嗜睡，但视力或听<br/>力中至少一项评为 4，或沟通评为 3；或昏睡/昏<br/>迷</span>
              </el-radio>
            </div>
          </el-radio-group>
          <h4 class="mt10">B.4 社会参与评估表</h4>
          <h5 class="mt10">B.4.1 生活能力</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">0分，除个人生活自理外(如饮食、洗漱、穿<br/>戴、二便)，能料理家务（如做饭、洗衣）或当<br/>家管理事务</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">1分，除个人生活自理外，能做家务，但欠<br/>好，家庭事务安排欠条理</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">2分，个人生活能自理；只有在他人帮助下<br/>才能做些家务，但质量不好</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">3分，个人基本生活事务能自理（如饮食、<br/>二便），在督促下可洗漱</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">4分，个人基本生活事务（如饮食、二便）<br/>需要部分帮助或完全依赖他人帮助</span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.4.2 工作能力</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">0分，原来熟练的脑力工作或体力技巧性工<br/>作可照常进行</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">1分，原来熟练的脑力工作或体力技巧性工<br/>作能力有所下降</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">2分，原来熟练的脑力工作或体力技巧性工<br/>作明显不如以往，部分遗忘</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">3分，对熟练工作只有一些片段保留，技能<br/>全部遗忘</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">4分，对以往的知识或技能全部磨灭</span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.4.3 时间/空间定向</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">0分，时间观念（年、月、日、时）清楚；<br/>可单独出远门，能很快掌握新环境的方位</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">1分，时间观念有些下降，年、月、日清楚<br/>但有时相差几天；可单独来往于近街，知道现住<br/>地的名称和方位，但不知回家路线</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">2分，时间观念较差，年、月、日不清楚，<br/>可知上半年或下半年；只能单独在家附近行动，<br/>对现住地只知名称，不知道方位</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">3分，时间观念很差，年、月、日不清楚，<br/>可知上午或下午；只能在左邻右舍间串门，对现<br/>住地不知名称和方位</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">4分，无时间观念；不能单独外出</span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.4.4 人物定向</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">0分，知道周围人们的关系，知道祖孙、叔<br/>伯、姑姨、侄子侄女等称谓的意义；可分辨陌<br/>生人的大致年龄和身份，可用适当称呼</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">1分，只知家中亲密近亲的关系，不会分辨<br/>陌生人的大致年龄，不能称呼陌生人</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">2分，只能称呼家中人，或只能照样称呼，<br/>不知其关系，不辨辈分</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">3分，只认识常同住的亲人，可称呼子女或<br/>孙子女，可辨熟人和生人</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">4分，只认识保护人，不辨熟人和生人</span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.4.5 社会交往能力</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio :label="6">
                <span class="line24">0分，参与社会，在社会环境有一定的适应<br/>能力，待人接物恰当</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">1分，能适应单纯环境，主动接触人，初见<br/>面时难让人发现智力问题，不能理解隐喻语</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">2分，脱离社会，可被动接触，不会主动待<br/>人，谈话中很多不适词句，容易上当受骗</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">3分，勉强可与人交往，谈吐内容不清楚，<br/>表情不恰当</span>
              </el-radio>
              <el-radio :label="6">
                <span class="line24">4分，难以与人接触</span>
              </el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10">B.4.6 社会参与总分</h5>
          <div class="mb10">上述5个项目得分之和</div>
          <el-input type="text" placeholder="请输入"></el-input>
          <h5 class="mt10">B.4 社会参与分级</h5>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio class="mb10" :label="3">0 能力完好：总分 0-2 分</el-radio>
              <el-radio class="mb10" :label="6">1 轻度受损：总分 3-7 分</el-radio>
              <el-radio class="mb10" :label="6">2 中度受损：总分 8-13 分</el-radio>
              <el-radio class="mb10" :label="6">3 重度受损：总分 14-20 分</el-radio>
            </div>
          </el-radio-group>
        </div>
        <!--CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC-->
        <div class="mt40">
          <h3 class="main-title ">老年人评估报告</h3>
          <h4>C.1 一级指标分级</h4>
          <h5>C.1.1 日常生活活动</h5>
          <div style="display:flex;align-items: center">
            <el-input type="text" placeholder="请输入"></el-input>
            <span style="padding-left: 10px">级</span>
          </div>
          <h5 class="mt10">C.1.2 精神状态</h5>
          <div style="display:flex;align-items: center">
            <el-input type="text" placeholder="请输入"></el-input>
            <span style="padding-left: 10px">级</span>
          </div>
          <h5 class="mt10">C.1.3 感知觉与沟通</h5>
          <div style="display:flex;align-items: center">
            <el-input type="text" placeholder="请输入"></el-input>
            <span style="padding-left: 10px">级</span>
          </div>
          <h5 class="mt10">C.1.4 社会参与</h5>
          <div style="display:flex;align-items: center">
            <el-input type="text" placeholder="请输入"></el-input>
            <span style="padding-left: 10px">级</span>
          </div>
          <h4 class="mt10">C.2 老年人能力初步等级</h4>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio class="mb10" :label="3">0 能力完好</el-radio>
              <el-radio class="mb10" :label="6">1 轻度失能</el-radio>
              <el-radio class="mb10" :label="6">2 中度失能</el-radio>
              <el-radio class="mb10" :label="6">3 重度失能</el-radio>
            </div>
          </el-radio-group>
          <h4>C.3 老年人能力初步等级</h4>
          <div class="mb10">1 有认知障碍/痴呆、精神疾病者，在原有能力级别上提高一个等级；</div>
          <div class="mb10">2 近 30 天内发生过 2 次及以上跌倒、噎食、自杀、走失者，在原有能力级别上提高一个等级；</div>
          <div class="mb10">3 处于昏迷状态者，直接评定为重度失能；</div>
          <div class="mb10">4 若初步等级确定为“3 重度失能”，则不考虑上述1-3中各情况对最终等级的影响， 等级不再提高；</div>
          <h4>C.4 老年人能力最终等级</h4>
          <el-radio-group>
            <div style="display:flex;flex-direction: column">
              <el-radio class="mb10" :label="3">0 能力完好</el-radio>
              <el-radio class="mb10" :label="6">1 轻度受损</el-radio>
              <el-radio class="mb10" :label="6">2 中度受损</el-radio>
              <el-radio class="mb10" :label="6">3 重度受损</el-radio>
            </div>
          </el-radio-group>
          <h5 class="mt10 beforeStyle pl10">评估员签名</h5>
          <div class="mb10">评估员1</div>
          <autograph/>
          <div class="mb10">评估员2</div>
          <autograph/>
          <h5 class="mt10 beforeStyle pl10">日期</h5>
          <el-input type="text" placeholder="请选择日期"></el-input>
          <h5 class="mt10 beforeStyle pl10">信息提供者签名</h5>
          <autograph/>
          <h5 class="mt10 beforeStyle pl10">日期</h5>
          <el-input type="text" placeholder="请选择日期"></el-input>
          <h5 class="mt10 beforeStyle pl10">评估结果</h5>
          <el-input type="text" placeholder="请输入"></el-input>
          <h4 class="mt10">注：老年人能力初步等级划分标准</h4>
          <h5 class="mt10">0 能力完好</h5>
          <div class="mb10">日常生活活动、精神状态、感知觉与沟通分级均为 0，社会参与分级为 0 或 1</div>
          <h5 class="mt10">1 轻度失能</h5>
          <div class="mb10">日常生活活动分级为 0，但精神状态、感知觉与沟通中至少一项分级为 1 及以上，或社会参与的分级为 2； 或日常生活活动分级为 1，精神状态、感知觉与沟通、社会参与中至少有一项的分级为 0 或 1</div>
          <h5 class="mt10">2 中度失能</h5>
          <div class="mb10">日常生活活动分级为 1，但精神状态、感知觉与沟通、社会参与均为 2，或有一项为 3； 或日常生活活动分级为 2，且精神状态、感知觉与沟通、社会参与中有 1-2 项的分级为 1 或 2</div>
          <h5 class="mt10">3 重度失能</h5>
          <div class="mb10">日常生活活动的分级为 3；<br/>
            或日常生活活动、精神状态、感知觉与沟通、社会参与分级均为 2；<br/>
            或日常生活活动分级为 2，且精神状态、感知觉与沟通、社会参与中至少有一项分级为 3</div>
        </div>
      </div>

    </div>
    </div>
    <!-- <div class="bottom-btn">
      <el-button type="primary" style="width: 110px">保存并提交</el-button>
    </div> -->
  </div>
</template>

<script>
  import autograph from "./components/autograph";
  export default {
    name: "computerTemplate",
    components: {
      autograph
    },
    data() {
      return {
        value1: []
      }
    }
  }
</script>

<style lang="scss" scoped>
  .phoneTemplate {
    width: 100%;
    height: calc(100vh - 225px);
    .main-box {
      overflow: hidden;
      height: calc(100vh - 300px);
      overflow-y: auto;
      .main-title {
        width: 100%;
        display:inline-block;
        text-align: center;
        margin: 20px 0;
      }

      .divcontent {
        /*margin: 0px 0 0px 0;*/
        width: 350px;
        margin: 0 auto;
     /*   overflow: hidden;
        height: calc(100vh - 300px);
        overflow-y: auto;*/
        padding-right: 20px;
        padding-bottom: 100px;
      }
    }
  }
  .mt10 {
    margin-top: 10px !important;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .mt40 {
    margin-top: 40px;
  }
  .pl10 {
    padding-left: 10px;
  }
  .line24 {
    line-height: 24px;
  }
  .beforeStyle {
    position: relative;
  }
  .beforeStyle:before {
    content: "*";
    color: red;
    position: absolute;
    top: 0;
    left: 0px;
    font-size: 14px;
  }
  h1,h2,h3,h4,h5,h6 {
    margin-top: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .bottom-btn {
    /*width: 100%;*/
    display: inline-block;
    text-align: center;
    line-height: 70px;
    /*margin: 0 auto;*/
    margin-left: 350px;
  }
</style>
